import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationService } from '../navigation/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private store: BehaviorSubject<any> = new BehaviorSubject([]);
  private profile: BehaviorSubject<any> = new BehaviorSubject([]);
  private token: BehaviorSubject<String> = new BehaviorSubject('');
  private role: BehaviorSubject<String> = new BehaviorSubject('');
  constructor(private navigationService: NavigationService) {}

  setData(data: any) {
    this.setItem('store', JSON.stringify(data.store));
    this.setItem('profile', JSON.stringify(data.profile));
    this.setItem('role', JSON.stringify(data.role));
    this.role.next(data.role);
    this.store.next(data.store);
    this.profile.next(data.profile);
  }

  getItem(key: string): any {
    return localStorage.getItem(key);
  }

  reloadData() : void {
    this.getItem('token')
    .then((token : any) => {
      this.token.next(token);
    });

    this.getItem('role')
    .then((role : any) => {
      this.role.next(role);
    });
  }

  getToken(): any {
    return this.token;
  }

  setToken(token: string): any {
    this.setItem('token', token);
    this.token.next(token)
  }

  setItem(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  getRole(): Observable<any> {
    return this.role;
  }

  setRole(role: string): any {
    this.setItem('role', role);
    this.role.next(role);
  }

  clear() {
    localStorage.clear();
    this.navigationService.navigate('login');
  }

  isAuthenticated(): boolean {
    const token = this.getItem('token');

    if (token) {
      this.token.next(token);
    }

    const role = this.getItem('role');

    if (role) {
      this.role.next(role);
    }

    return token ? true : false;
  }



}
