export const ROUTES: any[] = [


  {
    path: "/configuracion", title: "configuration.title", icon: "icon-home text-xl p-1", class: "has-sub", badge: "", badgeClass: "badge badge-pill badge-success float-right mr-1 mt-1", isExternalLink: false,tienda:['all'], role : ["store","root", "admin", "master"] ,
    submenu: [
      { path: "/configuracion/home", title: "configuration.home", icon: "icon-brush text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["store"] },
      { path: "/configuracion/landing", title: "Modificar Landing Page", icon: "icon-brush text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master"] },
    ]
  },

  { path: "/estadisticas", title: "statistics.title", icon: "icon-icon-23 text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root", "master", "store"] },
  { path: "/checkout-forms", title: "Checkout Forms", icon: "icon-icon-24 text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : [] },
  { path: "/reportes", title: "reports.title", icon: "icon-icon-24 text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root", "admin", "master"] },
  { path: "/tiendas", title: "stores.title", icon: "icon-store text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root", "admin", "master"] },
  { path: "/tiendas/myStore", title: "stores.my_title", icon: "icon-store text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["store","user"] },

  {
    path: "/productos", title: "products.title", icon: "icon-offer text-xl p-1", class: "has-sub", badge: "", badgeClass: "badge badge-pill badge-success float-right mr-1 mt-1", isExternalLink: false,tienda:['all'], role : ["root","master","store","user"] ,
    submenu: [
      { path: "/productos/create", title: "create-product.title", icon: "icon-add  text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
      { path: "/productos", title: "products.listaProductos", icon: "icon-list-alt  text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
      { path: "/categorias", title: "categories.title", icon: "icon-icon-3 text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
      { path: "/toppings", title: "Toppings", icon: "icon-icon-3 text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
      { path: "/etiquetas", title: "etiquetas.title", icon: "icon-icon-3 text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
      { path: "/artistas", title: "artists.title", icon: "icon-icon-3 text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
      { path: "/filtros", title: "Filtros", icon: "icon-icon-3 text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
      // { path: "/kits", title: "Kits", icon: "icon-icon-26 text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
      { path: "/variantes", title: "products.configuracion", icon: "icon-configuration  text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master", "admin"] },    ]
  },


  { path: "/clientes", title: "customers.title", icon: "icon-users text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["store","user"] },
  // {
  //   path: "/turnos", title: "turnos.title", icon: " icon-headphone text-xl p-1", class: "has-sub", badge: "", badgeClass: "badge badge-pill badge-success float-right mr-1 mt-1", isExternalLink: false,tienda:['all'], role : ["store"] ,
  //   submenu: [
  //     { path: "/turnos/services", title: "turnos.servicios", icon: "icon-calendar-alt text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
  //     { path: "/turnos/categories", title: "categories.title", icon: "icon-list-alt text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
  //     { path: "/turnos/personal", title: "turnos.personal", icon: "icon-user text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
  //     { path: "/turnos/feriados", title: "turnos.feriados", icon: "icon-calendar text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
  //     { path: "/turnos/calendar", title: "calendar", icon: "icon-calendar text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
  //   ]
  // },
  { path: "/envios", title: "Envios", icon: "icon-cart text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["store", "root"] },
  { path: "/pedidos", title: "orders.title", icon: "icon-cart text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["store", "root"] },
  { path: "/whatsapp", title: "Mensajeria", icon: "icon-whatsapp text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root", "master", "store", "admin"] },
  { path: "/campaign", title: "campaign", icon: "icon-bullhorn text-2xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, tienda:['all'], role : ["root", "master", "store", "admin"],
    submenu: [
      { path: "/campaign", title: "List", icon: "icon-list text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["store"] },
      { path: "/campaign/send", title: "Create", icon: "icon-add text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["store"] },
      { path: "/campaign/stats", title: "Stats", icon: "icon-icon-23 text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["store"] },
    ]
  },
  { path: "/settings", title: "confg.title", icon: "icon-slider-x text-2xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, tienda:['all'], role : ["root"],
  submenu: [
    { path: "/settings", title: "confg.list", icon: "icon-list-alt text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root"] },
    { path: "/settings/general", title: "confg.general", icon: "icon-home text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root"] },
  ]
},
  { path: "/compartir", title: "products.compartir", icon: "icon-share-alt text-xl p-1", class: "has-sub", badge: "", badgeClass: "badge badge-pill badge-success float-right mr-1 mt-1", isExternalLink: false, tienda:['all'], role : ["store"],
    submenu: [
      { path: "/compartir/whatsapp", title: "Whatsapp", icon: "icon-whatsapp text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
      { path: "/compartir/facebook", title: "Facebook", icon: "icon-facebook text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
      { path: "/compartir/instagram", title: "Instagram", icon: "icon-icon-53 text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
      { path: "/compartir/lists", title: "shares.list", icon: "icon-list text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root","master","store","user"] },
    ]
  },

  // { path: "/wholesales", title: "wholesales.title", icon: "icon-share-alt text-xl p-1", class: "has-sub", badge: "", badgeClass: "badge badge-pill badge-success float-right mr-1 mt-1", isExternalLink: false, tienda:['all'], role :  ["root", "master"],
  //   submenu: [
  //     { path: "/wholesales", title: "users.title", icon: "icon-list-alt text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root", "master"] },
  //     { path: "/wholesales/orders", title: "wholesales.listadoOrdersWholesale", icon: "icon-cart text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root", "master"] },
  //   ]
  // },
  // { path: "/configuracion", title: "configuration.title", icon: "icon-configuration text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root"] },
  { path: "/promociones", title: "promotions.title", icon: "icon-star text-xl p-1", class: "has-sub", badge: "", badgeClass: "badge badge-pill badge-success float-right mr-1 mt-1", isExternalLink: false, tienda:['all'], role : [],
    submenu: [
      { path: "/promociones", title: "promotions.list", icon: "icon-list-alt  text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root", "master", "admin"] },
      { path: "/promociones/create", title: "promotions.create", icon: "icon-add  text-xl", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root", "master", "admin"] },
    ]
  },
  { path: "/soporte", title: "buttons.support", icon: "icon-life-ring text-xl p-1", class: "has-sub", badge: "", badgeClass: "badge badge-pill badge-success float-right mr-1 mt-1", isExternalLink: false, tienda:['all'], role : ["root", "admin", "master"],
    submenu: [
      { path: "/soporte/orden", title: "orders.title", icon: "icon-cart text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root", "master", "admin"] },
      { path: "/soporte/producto", title: "products.title", icon: "icon-edit-alt text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root", "master", "admin"] },
      { path: "/soporte/change-categories", title: "support.cambiarCategoria", icon: "icon-edit text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root", "master", "admin"] },
      { path: "/soporte/approved-orders", title: "Aprobar ordenes", icon: "icon-done text-xl p-1", class: "", badge: "", badgeClass: "", isExternalLink: false, submenu: [], tienda:['all'], role : ["root", "master", "admin"] },
    ]
  },


]
