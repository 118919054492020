<div *ngIf="!tabsOrder && showOrdersTabs && !tabsOrderWholesale && !users && !support && !campaign" class="overflow-auto 2xl:overflow-visible w-full mb-4 md:mb-8 mt-0">
    <nav class="menu-tabs">
        <button [class.active]="activo" (click)=" activo = true; inactivo = false; all = false;children = false;  this.status.emit({status: 'active'}) ">
        {{'products.actives' | translate}}
        </button>
        <button *ngIf="path == productosPath" [class.active]="children" (click)=" activo = false; inactivo = false; all = false; children = true; this.status.emit({status: 'children'}) ">
          {{'products.heredados' | translate}}
        </button>
        <button [class.active]="inactivo" (click)=" activo = false; inactivo = true; all = false; children = false; this.status.emit({status: 'inactive'})">
        {{'products.inactives' | translate}}
      </button>
        <button [class.active]="all" (click)=" activo = false; inactivo = false; all = true; children = false; this.status.emit({status: 'all'}) ">
        {{'products.all' | translate}}
      </button>
    </nav>
</div>

<div *ngIf="tabsOrder && showOrdersTabs" class="overflow-auto 2xl:overflow-visible w-full mb-4 md:mb-8 mt-0">
    <nav class="menu-tabs">
        <ng-container *ngFor="let buttonTab of tabsOrders; let i = index">
            <button (click)="tabs = buttonTab" [class.active]="i === tabs.id" class="relative group">
              {{buttonTab.label | translate}}
              <span class="absolute w-4 h-4 top-1 right-1 flex items-center justify-center text-xs rounded-full" [ngClass]="i === tabs.id?' bg-principal text-white':' bg-gris-base text-white'">{{buttonTab.cantidad}}</span>
          </button>
        </ng-container>
    </nav>
</div>

<div *ngIf="tabsOrderWholesale" class="overflow-auto 2xl:overflow-visible w-full mb-4 md:mb-8 mt-0">
    <nav class="menu-tabs">
        <ng-container *ngFor="let buttonTab of tabsOrdersWholesale; let i = index">
            <button (click)="tabsWholesales = buttonTab" [class.active]="i === tabsWholesales.id">
              {{buttonTab.label | translate}}
          </button>
        </ng-container>
    </nav>
</div>

<div *ngIf="users" class="overflow-auto 2xl:overflow-visible w-full mb-4 md:mb-8 mt-0">
    <nav class="menu-tabs">
        <ng-container *ngFor="let buttonTab of tabsOrdersUsers; let i = index">
            <button (click)="tabsOrdersUsersMain = buttonTab" [class.active]="i === tabsOrdersUsersMain.id">
              {{buttonTab.label | translate}}
          </button>
        </ng-container>
    </nav>
</div>

<div *ngIf="support" class="overflow-auto 2xl:overflow-visible w-full mb-4 md:mb-8 mt-0">
    <nav class="menu-tabs">
        <ng-container *ngFor="let buttonTab of tabsSupport; let i = index">
            <button (click)="tabsSupportMain = buttonTab" [class.active]="i === tabsSupportMain.id">
              {{buttonTab.label | translate}}
          </button>
        </ng-container>
    </nav>
</div>

<div *ngIf="campaign" class="">
    <nav class="menu-tabs">
        <ng-container *ngFor="let buttonTab of tabsCampaing; let i = index">
            <button (click)="!clone && tabsCampaingMain = buttonTab" [class.active]="i === tabsCampaingMain.id">
              {{buttonTab.label | translate}}
          </button>
        </ng-container>
    </nav>
</div>