// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_URL_IMAGE: "https://apiflexy.byflexy.com/api/v2admin/files/",
  // API_URL: "https://apiflexy.byflexy.com/api/v2/",
  API_URL_IMAGE: "https://apidev.byflexy.com/api/v2/admin/files/",
  API_URL: "https://apidev.byflexy.com/api/v2/",

  CLIENT: 'test',

  API_URL_WHATSAPP_SOCKET: "http://whatsapp.byflexy.com:33444/",
  API_URL_WHATSAPP_CONFIG: "https://whatsapp.byflexy.com/api/v1/getChatCredentials/",
  DEBUG_WHATSAPP_SERVICE: true,
  PUBLIC_KEY: 'BI8B2PVUBVzhZxAOMLTJJkDIUZcmChFCZcP7UP4wOXdwurIXxWykVn0y3Knj_QswD1fCKkN5adGA9IuwCfcl0Sg',
  PRIVATE_KEY: 'cR7rVJQMfb-Syw6wyefRKCRKIIyR-EnZRM8q7Zscfhw'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
